:root {
  --primary-color: #3498db;
  --secondary-color: #2c3e50;
  --background-color: #f9f9f9;
  --card-background: #ffffff;
  --text-color: #333333;
  --border-color: #e0e0e0;
}

body {
  background-color: var(--background-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
}

.contract-management {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.title {
  font-size: 32px;
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 40px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.section {
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 25px;
  transition: box-shadow 0.3s ease;
}

.section:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 20px;
  color: var(--primary-color);
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--primary-color);
}

.contract-management-container {
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contract-management-scroll {
  max-height: 600px; /* Adjust this value to control how many cards are visible */
  overflow-y: auto;
  padding-right: 20px; /* Add some padding for the scrollbar */
}

.contract-management-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.contract-management-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contract-management-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.contract-management-card h3 {
  color: #2c3e50;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

/* Custom scrollbar styles */
.contract-management-scroll::-webkit-scrollbar {
  width: 8px;
}

.contract-management-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.contract-management-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.contract-management-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .title {
    font-size: 28px;
  }
  
  .grid {
    grid-template-columns: 1fr;
  }
  
  .contract-management-grid {
    grid-template-columns: 1fr;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #3498db;
    --secondary-color: #ecf0f1;
    --background-color: #2c3e50;
    --card-background: #34495e;
    --text-color: #f9f9f9;
    --border-color: #4a6278;
  }
}