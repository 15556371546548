

.hero1-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 40px;
    border-radius: 8px;
    margin-bottom: 40px;
}




.bubble-section {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}








.cta-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.cta-button:hover {
    background-color: #0056b3;
}







.hero1-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.hero1-image {
    flex: 1;
    margin-right: 20px;
}

.hero1-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.hero1-text {
    flex: 1;
}

.hero1-text h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}





