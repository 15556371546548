/* HomePage.css */

.homepage {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
}


.cta-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.cta-button:hover {
    background-color: #0056b3;
}




.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  padding: 1rem;
  list-style: none;
  margin: 0;
}

.nav-item {
  margin: 0 1rem;
}

.nav-item a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.nav-item a:hover {
  text-decoration: underline;
}

.bubble-section {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.key-features-section {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 20px; /* Space between items */
}

.feature {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.key-features-section h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.key-features-section p {
  font-size: 1em;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
  }
}

@media (max-width: 576px) {
  .features-grid {
    grid-template-columns: 1fr; /* One column on extra small screens */
  }
}


