/* AboutUsPage.css */

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.about-us-page {
  margin: 20px auto;
  padding: 20px;
  max-width: 1200px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.about-us-title {
  font-size: 2.5em;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.about-us-nav {
  background-color: #333;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.about-us-nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.about-us-nav li {
  display: inline;
}

.about-us-nav li button.nav-link {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.about-us-nav li button.nav-link:hover {
  background-color: #ddd;
}

section {
  margin-bottom: 40px;
}

section h2 {
  font-size: 2em;
  color: #000;
  margin-bottom: 20px;
}

section p, section ul {
  font-size: 1.1em;
  color: #000;
  line-height: 1.6;
}

section ul {
  list-style-type: disc;
  padding-left: 20px;
}

section ul li {
  margin-bottom: 10px;
}

.mission-section, .who-we-are-section, .what-we-do-section, .vision-section, .values-section, .team-section, .contact-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.values-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.values-section ul li {
  margin-bottom: 10px;
}

/* Founder Section */
.founder-section {
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
}

.founder-image {
  max-width: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.founder-section h3 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 10px;
}

.founder-section p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
}
