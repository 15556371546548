/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.marketplace-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.marketplace-page-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

/* Navigation Bar */
.marketplace-page-nav {
    display: flex;
    justify-content: center; /* Centered navigation links */
    background-color: #f8f9fa; /* Light background color */
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.marketplace-page-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center; /* Ensure vertical alignment */
}

.marketplace-page-nav li {
    margin-right: 10px;
}

.marketplace-page-nav .nav-link {
    text-decoration: none;
    color: #000000; /* Black text color */
    padding: 10px 15px;
    border: 1px solid #000000; /* Black border */
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5); /* White background with 50% transparency */
    transition: background-color 0.3s, color 0.3s;
}

.marketplace-page-nav .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% transparency on hover */
    color: #ffffff; /* White text on hover */
}

/* Mobile Navigation */
.mobile-nav {
    display: none;
}

/* Introduction Section */
.intro-section {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.intro-section h2 {
    font-size: 2.5em; /* Increased font size */
    margin-bottom: 10px;
    font-weight: bold; /* Bold text */
}

.intro-section p {
    font-size: 1.1em;
    line-height: 1.8; /* Increased line height */
    color: #555;
}

/* How to Use Section */
.how-to-use-section {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.how-to-use-section h2 {
    font-size: 2.5em; /* Increased font size */
    margin-bottom: 10px;
    font-weight: bold; /* Bold text */
}

.how-to-use-section p {
    font-size: 1.1em;
    line-height: 1.8; /* Increased line height */
    color: #555;
}

/* Button Styles */
.button {
    background-color: #61dafb; /* Blue background */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Shadow effect */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #4a9fd6; /* Darker blue on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    .marketplace-page {
        padding: 10px;
    }

    .marketplace-page-title {
        font-size: 2em;
    }

    .marketplace-page-nav {
        display: none;
    }

    .mobile-nav {
        display: block;
    }

    .mobile-nav-select {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        font-size: 1em;
        border: 1px solid #000000;
        border-radius: 5px;
        background-color: #f8f9fa;
    }

    .features {
        flex-direction: column;
        align-items: center;
    }

    .feature {
        flex: 1 1 100%;
        margin-bottom: 20px;
    }

    .intro-section h2,
    .how-to-use-section h2 {
        font-size: 2em;
    }

    .intro-section p,
    .how-to-use-section p {
        font-size: 1em;
        line-height: 1.6;
    }

    .button {
        width: 100%;
        text-align: center;
    }
}
