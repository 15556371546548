body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.dashboard-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.dashboard-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-header h4 {
  font-size: 2.5rem;
  color: #333;
  margin: 0;
}

.dashboard-header h6 {
  font-size: 1.5rem;
  color: #666;
  margin: 5px 0;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-content {
  padding: 20px;
}

.card-content h6 {
  font-size: 1.8rem;
  color: #333;
  margin: 0 0 10px;
}

.card-content p {
  font-size: 1.4rem;
  color: #666;
  margin: 0;
}

.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #f7f7f7;
  font-weight: bold;
}

.table td {
  background-color: #fff;
}

.button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.2rem;
}

.button:hover {
  background-color: #0056b3;
}

.collapse {
  margin-top: 20px;
}

.collapse .table {
  margin-top: 0;
}

.proposals-section {
  margin-top: 40px;
}

.proposals-section h6 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.bar-chart {
  margin-top: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Add these new styles */
.settings-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: auto;
  padding: 10px 15px;
  margin-top: 0;
  font-size: 1rem;
  margin-left: 20px;
  padding: 10px 15px;
  font-size: 1rem;
}
