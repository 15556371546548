/* Footer.css */
.contact-section {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #e9ecef;
}

.contact-section h2 {
  margin-bottom: 10px;
}

.contact-section p {
  margin: 0;
}

.contact-section a {
  color: #007bff;
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
}
