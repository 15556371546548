/* Variables */
:root {
  --primary-color: #3498db;
  --primary-hover: #2980b9;
  --secondary-color: #2ecc71;
  --secondary-hover: #27ae60;
  --text-color: #2c3e50;
  --background-color: #ecf0f1;
  --card-background: #ffffff;
  --border-color: #bdc3c7;
  --shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  --border-radius: 12px;
  --transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

/* Base Styles */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
}

/* Layout */
.token-management-container {
  max-width: 100%;
  margin: 1rem;
  padding: 1rem;
}

.token-management-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

/* Card Styles */
.token-management-card {
  background-color: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: all var(--transition);
}

.token-management-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow);
}

.token-management-card h3 {
  color: var(--primary-color);
  font-size: 1.25rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--primary-color);
}

/* Form Styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-color);
}

.form-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: 0.9rem;
  transition: all var(--transition);
}

.form-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

/* Button Styles */
.btn {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: var(--border-radius);
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all var(--transition);
}

.btn:hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn:active {
  transform: translateY(0);
  box-shadow: none;
}

.btn-secondary {
  background-color: var(--secondary-color);
}

.btn-secondary:hover {
  background-color: var(--secondary-hover);
}

/* Responsive Design */
@media (min-width: 576px) {
  .token-management-container {
    margin: 1.5rem;
    padding: 1.5rem;
  }

  .token-management-grid {
    gap: 1.5rem;
  }

  .token-management-card {
    padding: 1.25rem;
  }
}

@media (min-width: 768px) {
  .token-management-container {
    margin: 2rem auto;
    padding: 2rem;
    max-width: 90%;
  }

  .token-management-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }

  .token-management-card {
    padding: 1.5rem;
  }

  .token-management-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .form-input {
    padding: 0.75rem 1rem;
    font-size: 1rem;
  }

  .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .token-management-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .token-management-container {
    max-width: 1140px;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.token-management-card {
  animation: fadeIn 0.5s ease-out;
}

/* Accessibility */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  :root {
    --text-color: #ecf0f1;
    --background-color: #2c3e50;
    --card-background: #34495e;
    --border-color: #7f8c8d;
  }
}

/* Print Styles */
@media print {
  .token-management-container {
    box-shadow: none;
  }

  .btn {
    display: none;
  }
}