/* Root Variables for Theming */
:root {
  --primary-color: #FFFFFF; /* White */
  --secondary-color: #000000; /* Black */
  --background-color: #FFFFFF; /* White */
  --text-color: #000000; /* Black */
  --section-bg-color: #FFFFFF; /* White */
  --highlight-color: #000000; /* Black */
  --accent-color: #CCCCCC; /* Light gray */
  --shadow-color: rgba(0, 0, 0, 0.5); /* Subtle shadow */
  --max-content-width: 1200px; /* Max width for layout */
}

/* Global Styles */
body {
  font-family: 'Inter', sans-serif;
  line-height: 1.8;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1rem;
  font-weight: 700;
}

p {
  margin-bottom: 1rem;
}

a {
  color: var(--highlight-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Page Container */
.whitepaper-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: var(--max-content-width);
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
  z-index: 10;
}

/* Header Styles */
.whitepaper-header {
  background-color: white;
  color: black;
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
}

.whitepaper-header h1 {
  font-size: 3rem;
}

.whitepaper-header p {
  font-size: 1.5rem;
  opacity: 1; /* Full opacity to ensure text is fully visible */
}

/* Main Content */
.whitepaper-content {
  flex-grow: 1;
  padding: 4rem 2rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  opacity: 1;
}

.whitepaper-section {
  padding: 3rem 2rem;
  margin-bottom: 4rem;
  border-radius: 12px;
  background-color: var(--background-color); /* Set to a specific color */
  opacity: 1; /* Ensure full opacity */
  z-index: 10; /* Ensure it is above other elements */
  position: relative; /* Ensure z-index is applied */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle box shadow */
}

.whitepaper-section h2 {
  font-size: 2.25rem;
  color: black;
  margin-bottom: 1.5rem;
  opacity: 1;
  z-index: 10; /* Ensure it is above other elements */
  position: relative; /* Ensure z-index is applied */
}

.whitepaper-section p,
.whitepaper-section ul {
  font-size: 1.125rem;
  color: var(--text-color);
  opacity: 1; /* Ensure full opacity */
  z-index: 10; /* Ensure it is above other elements */
  position: relative; /* Ensure z-index is applied */
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.1); /* Add subtle text shadow */
}

.whitepaper-section ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.whitepaper-section li {
  margin-bottom: 0.75rem;
}

/* Footer */
.whitepaper-footer {
  background-color: white;
  color: black;
  text-align: center;
  padding: 1.5rem;
  font-size: 0.9rem;
  border-top: 2px solid var(--highlight-color);
  z-index: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .whitepaper-header {
    padding: 2rem 1rem;
  }

  .whitepaper-header h1 {
    font-size: 2rem;
  }

  .whitepaper-header p {
    font-size: 1.2rem;
  }

  .whitepaper-content {
    padding: 2rem 1rem;
  }

  .whitepaper-section {
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    background-color: var(--background-color); /* Ensure background color */
    opacity: 1; /* Ensure full opacity */
    z-index: 10; /* Ensure it is above other elements */
    position: relative; /* Ensure z-index is applied */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle box shadow */
  }

  .whitepaper-section h2 {
    font-size: 1.75rem; /* Increase font size for mobile */
    color: var(--highlight-color);
    margin-bottom: 1rem;
    opacity: 1;
    z-index: 10; /* Ensure it is above other elements */
    position: relative; /* Ensure z-index is applied */
  }

  .whitepaper-section p,
  .whitepaper-section ul {
    font-size: 1.25rem; /* Increase font size for mobile */
    line-height: 1.6; /* Adjust line height for better readability */
    color: var(--text-color);
    opacity: 1; /* Ensure full opacity */
    z-index: 10; /* Ensure it is above other elements */
    position: relative; /* Ensure z-index is applied */
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.1); /* Add subtle text shadow */
  }

  .whitepaper-footer {
    padding: 1rem;
    font-size: 0.8rem;
  }
}

@media (min-width: 1200px) {
  .whitepaper-content {
    padding: 5rem 0;
  }

  .whitepaper-header h1 {
    font-size: 3.5rem;
  }

  .whitepaper-header p {
    font-size: 1.75rem;
  }
}
