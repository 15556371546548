body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.navbar a:active, .navbar a:focus, .navbar a:hover {
  background-color: #fff;
  color: #333;
}

.admin-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-page-title {
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.admin-page-section {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.admin-page-section h2 {
  color: #555;
  font-size: 1.5em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.admin-page-section input[type="text"],
.admin-page-section input[type="number"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1em;
}

.admin-page-section button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.admin-page-section button:hover {
  background-color: #444;
}

.admin-page-nav {
  width: 100%;
  background-color: #333;
  padding: 10px;
  margin-bottom: 20px;
}

.admin-page-nav ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-x: auto;
}

.admin-page-nav li {
  margin: 0 5px;
}

.admin-page-nav .nav-link {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid #fff;
  white-space: nowrap;
}

.admin-page-nav .nav-link:active,
.admin-page-nav .nav-link:focus,
.admin-page-nav .nav-link:hover {
  background-color: #fff;
  color: #333;
}

/* Responsive design */
@media (max-width: 768px) {
  .navbar {
    justify-content: flex-start;  /* Changed from center to flex-start */
  }
  
  .admin-page-title {
    font-size: 1.5em;
  }
  
  .admin-page-section {
    padding: 15px;
  }
  
  .admin-page-section h2 {
    font-size: 1.2em;
  }
  
  .admin-page-nav ul {
    justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  body {
    font-size: 14px;
  }
  
  .navbar a {
    margin: 0 3px;  /* Adjusted margins */
    padding: 6px 10px;  /* Adjusted padding */
  }
  
  .admin-page {
    padding: 10px;
  }
  
  .admin-page-section {
    padding: 10px;
  }
  
  .admin-page-section input[type="text"],
  .admin-page-section input[type="number"],
  .admin-page-section button {
    padding: 10px;
  }
  
  .admin-page-nav .nav-link {
    padding: 6px 10px;
  }
}
