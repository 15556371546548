body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.App {
  text-align: center;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  padding: 1rem;
  list-style: none;
  margin: 0;
}

.nav-item {
  margin: 0 1rem;
}

.nav-item a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.nav-item a:hover {
  text-decoration: underline;
}

.logo {
   margin-left: auto;
  margin-bottom: 1rem;
  text-align: left;
   
}


.logo img {
  height: 50px;
   
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .mobile-navbar {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }

 .mobile-nav-item {
  margin: 0.5rem 0;
  width: 100%;
}

 .mobile-nav-item a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  padding: 0.5rem 1rem;
}

.mobile-nav-item a:hover {
  background-color: #000000;
  text-decoration: underline;
}

  .mobile-logo {
  margin-left: auto;
  margin-bottom: 1rem;
  text-align: right; /* Override the center alignment */
}

.mobile-logo img {
    height: 40px;
}


  .menu-button {
    display: block;
    font-size: 2rem;
    color: black;
    cursor: pointer;
    position: absolute;
    left: 1rem; /* Adjust the value as needed */
    top: 1rem;  /* Adjust the value as needed */
  }
}

footer {
  background-color: #2c3e50; /* Updated color to match the theme */
  color: black; /* Change text color to black */
  padding: 1rem;
  position: relative; /* Change this from fixed to relative */
  width: 100%;
  text-align: center;
}

footer a {
  color: black; /* Change link color to black */
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.loading {
  font-size: 1.5rem;
  color: #333;
  margin-top: 2rem;
}

.bubble-section {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}